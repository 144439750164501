<template>
  <v-card :loading="loading" :disabled="loading" class="mt-n10 moholand-shadow">
    <v-card-title>
      ویرایش سفارش
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />

        <v-row align="center" justify="end" class="mb-3">
          <v-btn
            class="mx-4"
            dark
            color="success"
            type="submit"
            :loading="loading"
          >
            ویرایش سفارش
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MySnackbar from "@/components/MySnackbar";
import VFormBase from "vuetify-form-base";
import { getErrorText } from "@/constants/GlobalConstants";
import { formPostPrepare, formGetPrepare } from "@/constants/PanelFormHandler";

export default {
  components: {
    VFormBase,
    MySnackbar,
  },
  created() {
    this.getData();
    this.getUsers();
    this.getCourses();
    this.getProducts();
  },

  data() {
    return {
      id: this.$route.params.id,
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("edit") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      loading: false,
      model: {
        user_id: 0,
        status: 0,
        desc: "",
        code: "",
        order_course: [],
        order_product: [],
      },
      schema: {
        user_id: {
          type: "CustomInput",
          inputType: "select",
          items: [],
          itemValue: "id",
          itemText: "value",
          label: "خریدار",
          rules: [Constants.rules.required],
          loading: true,
          required: true,
        },
        status: {
          type: "CustomInput",
          inputType: "select",
          items: [
            { id: 0, value: "در حال بررسی" },
            { id: 1, value: "تایید شده" },
            { id: 2, value: "رد شده" },
          ],
          itemValue: "id",
          itemText: "value",
          label: "وضعیت",
          rules: [Constants.rules.required],
          required: true,
        },
        code: {
          type: "CustomInput",
          label: "کد سفارش",
          rules: [Constants.rules.required],
          required: true,
          disabled: true,
        },
        order_course: {
          type: "CustomInput",
          label: "دوره های سفارش",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "title",
          loading: true,
          multiple: true,
        },
        order_product: {
          type: "CustomInput",
          label: "فایل های سفارش",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "value",
          loading: true,
          multiple: true,
          col: { cols: 12 },
        },
        desc: {
          type: "CustomInput",
          inputType: "textarea",
          label: "توضیحات",
          required: true,
          col: { cols: 12 },
        },
      },
    };
  },

  methods: {
    getData() {
      this.loading = true;
      MyAxios.get("/orders/index/" + this.id, {
        params: {
          with: ["orderProduct", "orderCourse", "user", "payment"],
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          formGetPrepare(response, this.model);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getUsers() {
      this.loading = true;
      MyAxios.get("/users/index", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.schema.user_id = {
            ...this.schema.user_id,
            loading: false,
            items: response.data.map((val) => ({
              id: val.id,
              value: val.first_name + " " + val.last_name,
            })),
          };
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getCourses() {
      if (!this.schema.order_course.loading)
        this.schema.order_course.loading = true;

      MyAxios.get("/courses/index", {
        params: {
          conditions: {
            status: 1,
          },
        },
      })
        .then((response) => {
          this.schema.order_course.loading = false;
          this.schema.order_course.items = response.data.data;
        })
        .catch((error) => {
          this.schema.order_course.loading = false;
        });
    },
    getProducts() {
      MyAxios.get("/products/index", {
        params: {
          noPaginate: true,
          conditions: {
            status: 1,
          },
        },
      })
        .then((response) => {
          console.log(response.data);
          this.schema.order_product.loading = false;
          this.schema.order_product.items = response.data.map((val) => ({
            id: val.id,
            value: val.title,
          }));
        })
        .catch((error) => {
          this.schema.order_product.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = formPostPrepare(this.model);

        formData.append(
          "orderCourses",
          JSON.stringify(this.model.order_course)
        );

        formData.append(
          "orderProducts",
          JSON.stringify(this.model.order_course)
        );

        MyAxios.post(`orders/${this.id}/update`, formData)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "سفارش با موفقیت ویرایش شد",
              type: "success",
            });
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: getErrorText(error),
            });
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
  },
};
</script>

<style></style>
